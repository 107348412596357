/*
.table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
*/


.mainTable {
   height: 500px;
   width: 700px;
   overflow: auto;
}

.mainTableDef {
  /* height: 500px; */
  width: 700px;
  overflow: auto;
}

.tableIO_main {
  /* color: aquamarine; */
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  background-color: rgba(33,36,35,0.4);
}

.tableIO_main td, #customers th {
  /*border: 1px solid #ddd;*/
  /*padding: 8px;*/
}

.tableIO_main tr:nth-child(even){
  background-color: rgba(33,36,35,0.4);
}

.tableIO_main tr:hover {background-color: rgba(33,36,35,0.8);}

.tableIO_main th {
  padding-top: 1px;
  padding-bottom: 1px;
  /* text-align: left; */
  /*color: white;*/
}

.tableIO_main tr {
  text-align: center;
}

.rowInput {
  padding: 3px;
  font-size: 16px;
  width: 70px;
  background-color: grey;
}

.asyncButton {
  position: relative;
  /*
  appearance: none;
  background: none;
  outline: none;
  border: 2px solid transparent;
  */
 
  /*
  background-color: rgba(112, 76, 182, 0.1);
  color: rgb(112, 76, 182);
  padding-bottom: 4px;
  padding-left: 12px;
  padding-right: 12px;
  */

  border-radius: 4px;
  transition: all 0.15s;
}

.asyncButton:hover,
.asyncButton:focus {
  border: 2px solid rgba(112, 76, 182, 0.4);
}


.asyncButton:after {
  content: '';
  background-color: rgba(112, 76, 182, 0.15);
  /*display: block;*/
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  transition: width 1s linear, opacity 0.5s ease 1s;
}

.asyncButton:active:after {
  width: 0%;
  opacity: 1;
  transition: 0s;
}
