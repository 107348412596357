.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.main_Con {
  background-color: aquamarine;
  height: 10vh;
}

.top {
  /* background-color: black; */
  background-image: linear-gradient(to right, #05235E , #083EA7);
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-logo {
  padding-left: 20px;
  font-weight: bold;
  font-size: 22px;
}

.headerFlex1 {
  flex: 1;
  margin-left: 40px;
}

.header-user {
  padding-right: 20px;
  font-weight: bold;
  font-size: 18px;
}

.header-user_type {
  padding-right: 20px;
  font-style: italic;
  font-size: 14px;
}

.MainPage {
  height: 80vh;
  position: relative;
}

.maniCon {
  display: flex;
  height: 80%;

}

.mainSvg {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: -10px;
  opacity: 0.6;
}

.mainPageBtn {
  height: 300px;
  /* background-color: #05235E; */
  /* height: 100% */
}

.mainPageBtnBox {
  /* background-color: #083EA7; */
}

.mainTableBox {
 margin-left: 350px;
 margin-top: 100px;
}

.button {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 5px;
  font-family: Caveat;
}

.buttonLogIn {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 14px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  width: 100%;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 5px;
}

.button1 {
  background-color: white; 
  color: black; 
  border: 2px solid #4CAF50;
}

.button1:hover {
  background-color: #4CAF50;
  color: white;
}

.button2 {
  background-color: white; 
  color: black; 
  border: 2px solid #008CBA;
}

.button2:hover {
  background-color: #008CBA;
  color: white;
}

.button3 {
  background-color: white; 
  color: black; 
  border: 2px solid #FFA500;
}

.button3:hover {
  background-color: #FFA500;
  color: white;
}

.serviceMain {
  background-color: grey;
  width: 100%;
}

.serviceBox {
  height: 500px;
  max-width: 1000px;
  margin: 80px;
  background-color: #05235E;
  border-radius: 10px;
}

.border {
  border: 1px solid black;
}

.signatureCanvas {
  border: 1px solid black;
  /* width: 100%;
  min-height: 500px; */
}

.mainSim {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFA500;
  width: 100%;
  gap: 15px;
}

.boxSim {
  width: 160px;
  height: 160px;
  border: 1px solid rgb(231, 114, 71);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  color: green;
  background-color:  rgba(48, 49, 49, 0.2);
}

.boxSim:hover {
  opacity: 0.6;
  
}